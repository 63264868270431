<template>
  <div v-if="title" class="kt-divider">
    <span></span>
    <span>{{ title }}</span>
    <span></span>
  </div>
</template>

<script>
  export default {
    name: "index",
    props: {
      title: {
        type: String
      },
    }
  }
</script>

<style scoped>

</style>
