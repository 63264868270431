import { mapGetters } from "vuex";
import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
import {
  GET_COLLECTIONS,
  GET_COLLECTION, SEARCH_COLLECTION,
  STORE_COLLECTION, UPDATE_COLLECTION
} from "@/store/modules/collection.module";

export default {
  computed: {
    ...mapGetters(['collections', 'collectionsMeta', 'collection', 'collectionError', 'searchCollectionList', 'searchCollectionListMeta'])
  },
  methods: {
    getCollection(id, actions = {
      begin: ()=>{ this.$emit('loading'); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_COLLECTION, id),
      actions
      );
    },
    getCollections(page = 1, title = '', actions = {
      begin: ()=>{ this.showBusy('collectionBusy'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.hideBusy('collectionBusy'); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(
        this.$store.dispatch(GET_COLLECTIONS, {page, title}),
        actions
      );
    },
    storeCollection(formData = {}, actions = {
      begin: ()=>{ this.$emit('storing'); },
      after: (response, state)=>{ this.$emit('stored', {response, state}); },
    }){
      return mixinPromise(
        this.$store.dispatch(STORE_COLLECTION, formData),
        actions
      );
    },
    updateCollection(formData = {}, actions = {
      begin: ()=>{ this.$emit('updating'); },
      after: (response, state)=>{ this.$emit('updated', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(UPDATE_COLLECTION, formData),
      actions
      );
    },
    searchCollection(title = '', page = 0, actions = {
      begin: ()=>{ this.$emit('search:begin'); },
      after: (response, state)=>{ this.$emit('search:end', {response, state}); },
    }){
      return mixinPromise(
        this.$store.dispatch(SEARCH_COLLECTION, {title, page}),
        actions
      );
    },
  }
}
