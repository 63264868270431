<template>
  <b-overlay :show="collectionBusy">
    <portlet title="توپلام ئۇچۇرى تەھرىرلەش">
      <template v-slot:body>
        <collection-form
          ref="collection"
          action="edit"
          :model-id="collectionId"
          @loading="onLoading"
          @loaded="onLoaded"
          @updating="onLoading"
          @updated="onUpdated"
        />
      </template>
      <template v-slot:foot>
        <b-button id="submit" @click="handleSubmit" variant="primary">
          ساقلاش
        </b-button>
        <b-button class="ml-2" variant="danger" @click="onClickReset">
          بىكار قىلىش
        </b-button>
      </template>
    </portlet>
  </b-overlay>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import CollectionForm from "@v@/components/forms/CollectionForm";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import { debugConsole } from "@c@/helpers/helpers";
  export default {
    name: "index",
    components: { Portlet, CollectionForm },
    mixins: [ formBusyMixin, sweetAlertMixin ],
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'توپلام باشقۇرۇش', route: {name: 'collection.home'}});
      BreadCrumbs.addBreadCrumb({title: 'توپلام ئۇچۇرى تەھرىرلەش'});
      if ( this.$refs.hasOwnProperty('collection') ){
        this.form = this.$refs['collection'];
      }
    },
    computed: {
      collectionId(){
        if ( this.$route.hasOwnProperty('params') )
          return this.$route.params.id;
      },
    },
    data(){
      return {
        collectionBusy: false,
        form: {}
      };
    },
    methods: {
      handleSubmit(){
        this.form.onSubmit();
      },
      onLoading(){
        this.showBusy('collectionBusy');
        debugConsole({prefix: 'collection', message: 'onLoading'})
      },
      onLoaded({response, state}){
        this.hideBusy('collectionBusy');
        debugConsole({prefix: 'collection', message: `onLoaded: ${state}`});
      },
      onUpdated({response, state}){
        this.hideBusy('collectionBusy');
        debugConsole({prefix: 'collection', message: `onUpdated: ${state}`});
        if ( state === 'success' ){
          this.success({ message: 'توپلام ئۇچۇرى تەھرىرلەش مۇۋاپىقىيەتلىك بولدى.' });
        }
      },
      onClickReset(){
        this.$router.replace({name: 'collection.home'});
      },
    }
  }
</script>

<style scoped>

</style>
