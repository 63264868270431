<template>
  <div class="region-home position-relative">
    <b-overlay :show="collectionBusy">
      <pagination :meta="collectionsMeta" place-holder="توپلام نامىنى كىرگۈزۈپ ئىزدەڭ" @change-page="onChangePage" @refresh="onRefresh">
        <b-button slot="action" :to="{name: 'collection.store'}" class="btn btn-label-success btn-sm border-0">
          يېڭىدىن قوشۇش
        </b-button>
      </pagination>
      <b-row>
        <b-col v-for="collection in collections" :key="`collection_${collection.id}`" md="3" sm="6">
          <portlet :title="collection.title" body-fluid body-class="px-3 pb-3 pt-0" headNoBorder head-class="px-2" head-size="sm">
            <template slot="toolbar">
              <b-dropdown v-b-tooltip title="مەشغۇلات" toggle-class="text-decoration-none border-0 btn btn-clean btn-sm btn-icon btn-icon-md" no-caret>
                <template v-slot:button-content>
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-text >
                  <b-checkbox switch v-model="collection.is_show" @change="onChangeSwitch( collection.id, 'is_show', $event )"> ھالىتى </b-checkbox>
                  <b-checkbox switch v-model="collection.is_hidden" @change="onChangeSwitch( collection.id, 'is_hidden', $event )"> يوشۇرامسىز ؟ </b-checkbox>
                  <b-checkbox switch v-model="collection.in_home" @change="onChangeSwitch( collection.id, 'in_home', $event )"> باشبەتتە كۆرۈنەمدۇ ؟ </b-checkbox>
                  <b-checkbox switch v-model="collection.is_recommend" @change="onChangeSwitch( collection.id, 'is_recommend', $event )"> تەۋسىيەلىكمۇ ؟ </b-checkbox>
                </b-dropdown-text>
                <b-dropdown-divider />
                <b-dropdown-item :to="{name: 'collection.edit', params: {id: collection.id}}">
                  <i class="fa fa-info-circle"></i> تەھرىرلەش
                </b-dropdown-item>
                <b-dropdown-item @click="onClickDelete(collection.id)">
                  <i class="fa fa-trash"></i> ئۆچۈرۈش
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template slot="body">
              <div class="kt-widget-item-container">
                <div class="kt-widget kt-widget--user-profile-2">
                  <div class="kt-widget__body">
                    <div class="kt-widget__item rounded" style="height: 120px;overflow: hidden;">
                      <b-img fluid-grow rounded="" center :src="collection.cover_image_preview" />
                    </div>
                    <div class="kt-widget__item">
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">كىنو سانى: </span>
                        <span class="kt-widget__data"> {{ collection.films_count || 0 }} پارچە </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <no-result :visible="!collections.length" background="warning" title="ئۇچۇر تېپىلمىدى" desc="مۇناسىۋەتلىك ئ‍ۇچۇر تېپىلمىدى، يېڭىدىن توپلام قوشۇڭ." >
        <template slot="action">
          <b-button :to="{name: 'collection.store'}" class="btn-custom btn-bold btn-upper btn-font-sm  btn-warning">توپلام قوشۇش</b-button>
        </template>
      </no-result>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import NoResult from "@v@/partials/content/NoResult";
  import collectionMixin from "@m@/collection.mixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import pagination from "@v@/components/paginate";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import commonMethodMixin from "@m@/common/common.method.mixin";
  import msgBoxConfirmMixin from "@m@/common/msgBoxConfirmMixin";
  import {SET_COLLECTION_ERROR} from "@/store/modules/collection.module";

  export default {
    name: "index",
    components: { Portlet, pagination, NoResult },
    mixins: [collectionMixin, formBusyMixin, commonMethodMixin, msgBoxConfirmMixin],
    data(){
      return {
        collectionBusy: false,
        page: 1,
        title: ''
      };
    },
    computed: {},
    created() {
      this.getCollections();
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'توپلام باشقۇرۇش', route: {name: 'collection.home'}});
      BreadCrumbs.addBreadCrumb({title: 'بارلىق توپلام'});
    },
    methods: {
      onChangePage(e){
        let { title, page } = e;
        this.page = page; this.title = title;
        this.getCollections(page, title);
      },
      onRefresh(title){
        this.getCollections(1, title);
      },
      onChangeSwitch(id, column, value){
        this.switchColumn({
          resource: 'films/collections',
          id, column, mutationType: SET_COLLECTION_ERROR,
          begin: ()=>{ this.showBusy('collectionBusy') },
          after: ()=>{ this.hideBusy('collectionBusy') },
        });
      },
      onClickDelete(id){
        this.msgBoxConfirm({
          message: 'مەزكۇر توپلام ئۇچۇرىنى ئۆچۈرسىڭىز ئەسلىگە قايتۇرالمايسىز، يەنىلا ئۆچۈرۈشنى جەزىملەشتۈرەمسىز ؟',
          confirmCallback: confirm=>{
            if ( confirm ){
              this.deleteModel({
                id, mutationType: SET_COLLECTION_ERROR, resource: 'films/collections',
                begin: ()=>{ this.showBusy('collectionBusy')},
                after: ()=>{
                  let { title, page } = this;
                  this.getCollections(page, title);
                  this.hideBusy('collectionBusy');
                },
              })
            }
          }
        });
      }
    }
  }
</script>

<style scoped>

</style>
